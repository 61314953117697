import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/main.scss";
import GlobalSpinner from "./components/GlobalSpinner";

const App = React.lazy(
	async () =>
		await import(
			/* webpackChunkName: "main.app" */
			"./App"
		)
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.Suspense fallback={<GlobalSpinner />}>
		<App />
	</React.Suspense>
);
